import {
  FaSnapchat,
  FaInstagram,
  FaWhatsapp,
  FaPhoneAlt,
} from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/X-sm100',
  },
  {
    id: 2,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://instagram.com/x.sm100',
  },
  {
    id: 3,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966590104629',
  },
  { id: 4, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0590104629' },
]
export const restaurants = [
  {
    id: 1,
    name: 'فرع عنبه و كوكيز',
    distance: 5,
    addrress: 'شارع الأمير سلطان ، الروضة الجنوبية ، شرورة 68371',
    open: true,
    open24h: true,
    searchQuery: 'شرورة',
    coords: { lat: 17.5001632, lng: 47.1053984 },
  },
  {
    id: 2,
    name: '2فرع عنبه و كوكيز',
    distance: 5,
    addrress: 'شارع الأمير سلطان ، الروضة الجنوبية ، شرورة 68371',
    open: true,
    open24h: true,
    searchQuery: 'شرورة',
    coords: { lat: 16.5000001, lng: 47.1053700 },
  },
]
export const data = [
  {
    id: 1,
    title: 'ورق عنب',
    image: '../../../img/list/grape.png',
    selected: true,
    items: [
      {
        id: 1,
        title: 'ورق عنب صغير',
        description: 'بوكس صغير العدد 10 حبات',
        image: '../../img/food/enbbokssmol_11_11zon.jpg',
        price: 20,
        calories: 12,
        available: true,
        selected: false,
        size: {
          small: 20,
          medium: 25,
          large: 30,
        },
        extras: [
          { name: 'ليمون', price: 1 },
          { name: 'طماطم', price: 1 },
          { name: 'ثوم', price: 1 },
          { name: 'بطاطس', price: 1 },
        ],
        edits: [
          'بدون مخلل',
          'بدون بطاطس',
          'بدون شطة',
          'بدون ليمون',
          'بدون طماط',
        ],
      },
      {
        id: 2,
        title: 'ورق عنب وسط',
        description: 'بوكس وسط العدد 30 حبات',
        image: '../../img/food/enbboksmed_10_11zon.jpg',
        price: 46,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'ورق عنب كبير',
        description: 'بوكس كبير العدد 60 حبات',
        image: '../../img/food/3nb-box-kaber.jpg',
        price: 76,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'ورق عنب عائلي',
        description: 'بوكس عائلي العدد 100 حبات',
        image: '../../img/food/enbboksaila_9_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'ورق عنب عائلي',
        description: 'بوكس عائلي العدد 100 حبات',
        image: '../../img/food/enbboksaila_9_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'ورق عنب عائلي',
        description: 'بوكس عائلي العدد 100 حبات',
        image: '../../img/food/enbboksaila_9_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'ورق عنب عائلي',
        description: 'بوكس عائلي العدد 100 حبات',
        image: '../../img/food/enbboksaila_9_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 2,
    title: 'الملفوف',
    image: '../../../img/list/cabbage.png',
    selected: false,
    items: [
      {
        id: 5,
        title: 'ملفوف صغير',
        description: 'بوكس صغير العدد 10 حبة',
        image: '../../img/food/malfofsmol_17_11zon.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'ملفوف وسط',
        description: 'بوكس وسط العدد 30 حبة',
        image: '../../img/food/malfofmed_16_11zon.jpg',
        price: 46,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'ملفوف كبير',
        description: 'كبير العدد 60 حبة',
        image: '../../img/food/malfoflarg_15_11zon.jpg',
        price: 76,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'ملفوف عائلي',
        description: 'بوكس عائلي العدد 100 حبة',
        image: '../../img/food/malfofaila_14_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 3,
    title: 'فتة',
    image: '../../../img/list/fattah.png',
    selected: false,
    items: [
      {
        id: 23,
        title: 'فتة ورق عنب صغير',
        description: 'بوكس صغير',
        image: '../../img/food/fatasmol_16_11zon.jpg',
        price: 19,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 24,
        title: 'فتة ورق عنب كبير',
        description: 'بوكس كبير',
        image: '../../img/food/fatalarg_15_11zon.jpg',
        price: 36,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 25,
        title: 'فتة ورق عنب عائلي',
        description: 'بوكس عائلي',
        image: '../../img/food/fataaili_12_11zon.jpg',
        price: 95,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 26,
        title: 'فتة ورق جمعات',
        description: 'بوكس الجمعات',
        image: '../../img/food/fataboksgamat.jpg',
        price: 105,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 4,
    title: 'محاشي خضار',
    image: '../../../img/list/vegetables.png',
    selected: false,
    items: [
      {
        id: 9,
        title: 'محشي بطاطس صغير',
        description: 'بوكس صغير العدد 3 حبات',
        image: '../../img/food/btatasmol_8_11zon.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'محشي بطاطس وسط',
        description: 'بوكس وسط العدد 7 حبات',
        image: '../../img/food/محشيبطاطس.jpg',
        price: 39,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 11,
        title: 'محشي بطاطس كبير',
        description: 'بوكس كبير العدد 15 حبة',
        image: '../../img/food/btatalarg_7_11zon.jpg',
        price: 75,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 12,
        title: 'محشي الفلفل الرومي صغير',
        description: 'بوكس صغير العدد 3 حبات',
        image: '../../img/food/flfismol_18_11zon.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 13,
        title: 'محشي الفلفل الرومي وسط',
        description: 'بوكس وسط العدد 7 حبات',
        image: '../../img/food/محشيفلفل.jpg',
        price: 39,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 14,
        title: 'محشي الفلفل الرومي كبير',
        description: 'بوكس كبير العدد 15 حبة',
        image: '../../img/food/flfllarg_1_11zon.jpg',
        price: 75,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 15,
        title: 'محشي الكوسة صغير',
        description: 'بوكس صغير العدد 3 حبات',
        image: '../../img/food/kosasmol_5_11zon.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 16,
        title: 'محشي الكوسة وسط',
        description: 'بوكس وسط العدد 7 حبات',
        image: '../../img/food/محشيكوسه.jpg',
        price: 39,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 17,
        title: 'محشي الكوسة كبير',
        description: 'بوكس كبير العدد 15 حبة',
        image: '../../img/food/kosalarg_4_11zon.jpg',
        price: 75,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 18,
        title: 'محشي البصل صغير',
        description: 'بوكس صغير العدد 5 حبات',
        image: '../../img/food/baslsmol_3_11zon.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 19,
        title: 'محشي البصل وسط',
        description: 'بوكس وسط العدد 11 حبة',
        image: '../../img/food/بصل.jpg',
        price: 39,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 20,
        title: 'محشي البصل كبير',
        description: 'بوكس كبير العدد 20 حبة',
        image: '../../img/food/basllarg_2_11zon.jpg',
        price: 75,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 21,
        title: 'محاشي مشكل صغير',
        description: 'بوكس صغير العدد 5 حبات',
        image: '../../img/food/محشيمشكل.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 22,
        title: 'الدولمة عائلي',
        description: 'بوكس عائلي مشكل محاشي',
        image: '../../img/food/دولمه.jpg',
        price: 79,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 5,
    title: 'موالح',
    image: '../../../img/list/spring-rolls.png',
    selected: false,
    items: [
      {
        id: 27,
        title: 'كبه دجاج',
        description: 'عدد 8 حبة',
        image: '../../img/food/kbh3.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 28,
        title: 'كبه لحم',
        description: 'عدد 8 حبة',
        image: '../../img/food/kbh3.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 29,
        title: 'كبه مشكل:دجاج - لحم',
        description: 'عدد 8 حبة',
        image: '../../img/food/kbh3.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 30,
        title: 'مسخن رول مقلي',
        description: 'عدد 10 حبة',
        image: '../../img/food/ms5n.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 31,
        title: 'مسخن رول كلاسيك فرن',
        description: 'عدد 10 حبة',
        image: '../../img/food/ms5n-frn.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 32,
        title: 'رولات الجبن',
        description: 'عدد 10 حبة',
        image: '../../img/food/roll-jbn.jpg',
        price: 20,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 33,
        title: 'موالح',
        description: 'بوكس عائلي مشكل',
        image: '../../img/food/بوكسموالح.jpg',
        price: 89,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 6,
    title: 'معجنات',
    image: '../../../img/list/tames.png',
    selected: false,
    items: [
      {
        id: 34,
        title: 'معجنات مشكلة كبير',
        description: 'بوكس معجنات مشكلة كبير',
        image: '../../img/food/fatairmoshakla_14_11zon.jpg',
        price: 45,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 35,
        title: 'معجنات مشكلة وسط',
        description: 'بوكس معجنات مشكلة وسط',
        image: '../../img/food/m3jnat-wst.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 36,
        title: 'سمبوسة دجاج',
        description: 'عدد 20 حبة',
        image: '../../img/food/smbosa.jpg',
        price: 25,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 37,
        title: 'سمبوسة لحم',
        description: 'عدد 20 حبة',
        image: '../../img/food/smbosa.jpg',
        price: 25,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 38,
        title: 'سمبوسة جبنة بيضاء',
        description: 'عدد 20 حبة',
        image: '../../img/food/smbosa.jpg',
        price: 22,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 39,
        title: 'سمبوسة خضار',
        description: 'عدد 20 حبة',
        image: '../../img/food/smbosa.jpg',
        price: 22,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 40,
        title: 'سمبوسة مشكله',
        description: 'عدد 20 حبة',
        image: '../../img/food/smbosa.jpg',
        price: 23,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 7,
    title: 'مفرزنات',
    image: '../../../img/list/preserve.png',
    selected: false,
    items: [
      {
        id: 1,
        title: 'كبه دجاج ( مفرزنه )',
        description: 'العدد 10 حبات',
        image: '../../img/food/bkh-djaj.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: 'كبه لحم ( مفرزنه )',
        description: 'العدد 10 حبات',
        image: '../../img/food/bkh-djaj.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'كبه مشكل دجاج - لحم ( مفرزنه )',
        description: 'العدد 10 حبات',
        image: '../../img/food/bkh-djaj.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'مسخن رول مقلي ( مفرزن )',
        description: 'العدد 13 حبه',
        image: '../../../img/food/ms5n-m8li.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: 'مسخن رول كلاسيك ( مفرزن )',
        description: 'العدد 13 حبه',
        image: '../../../img/food/ms5n-roll-frn.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'رولات الجبن ( مفرزنه )',
        description: 'العدد 13 حبه',
        image: '../../../img/food/rollat-jbn.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'سمبوسة دجاج ( مفرزنه )',
        description: 'العدد 23 حبه',
        image: '../../../img/food/smbosa-djaj.jpg',
        price: 26,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'سمبوسة لحم ( مفرزنه )',
        description: 'العدد 23 حبه',
        image: '../../../img/food/smbosa-djaj.jpg',
        price: 26,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'سمبوسة خضار ( مفرزنه )',
        description: 'العدد 23 حبه',
        image: '../../../img/food/smbosa-djaj.jpg',
        price: 23,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'سمبوسة جبنة بيضاء ( مفرزنه )',
        description: 'العدد 23 حبه',
        image: '../../../img/food/smbosa-djaj.jpg',
        price: 24,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 8,
    title: 'كوكيز',
    image: '../../../img/list/cookies.png',
    selected: false,
    items: [
      {
        id: 41,
        title: 'كب كوكيز نوتيلا صغير',
        description: 'بوكس صغير العدد 6 حبة',
        image: '../../img/food/kpkokenotla_8_11zon.jpg',
        price: 26,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 42,
        title: 'كب كوكيز نوتيلا كبير',
        description: 'بوكس كبير العدد 12 حبة',
        image: '../../img/food/kpkokenotlalarg_10_11zon.jpg',
        price: 42,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 43,
        title: 'كب كوكيز نوتيلا حبة',
        description: 'حبة واحدة',
        image: '../../img/food/kpkokenotlahaba_9_11zon.jpg',
        price: 6,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 44,
        title: 'كب كوكيز ماكس صغير',
        description: 'بوكس صغير ( بيستاشيو و نوتيلا ) العدد 6 حبة',
        image: '../../img/food/kpkokemxssmol_7_11zon.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 45,
        title: 'كب كوكيز ماكس كبير',
        description: 'بوكس كبير ( بيستاشيو و نوتيلا ) العدد 12 حبة',
        image: '../../img/food/kpkokemxslarg_6_11zon.jpg',
        price: 45,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 46,
        title: 'كب كوكيز ماكس حبة',
        description: 'حبة واحدة',
        image: '../../img/food/kpmks.jpg',
        price: 7,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 47,
        title: 'كوكيز نيويورك كلاسيك كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/nuouork10_9_11zon.jpg',
        price: 42,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 48,
        title: 'كوكيز نيويورك كلاسيك صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/نيويورك.jpg',
        price: 27,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 49,
        title: 'كوكيز نيويورك كلاسيك حبة',
        description: 'حبة واحدة',
        image: '../../img/food/nuouork1_4_11zon.jpg',
        price: 8,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 50,
        title: 'كوكيز نيويورك شوكلت كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/nuouork10choklt_11_11zon.jpg',
        price: 42,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 51,
        title: 'كوكيز نيويورك شوكلت صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/شوكلت.jpg',
        price: 27,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 52,
        title: 'كوكيز نيويورك شوكلت حبة',
        description: 'حبة واحدة',
        image: '../../img/food/nuouork1choklt_6_11zon.jpg',
        price: 8,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 53,
        title: 'كوكيز نيويورك رد فيلفيت كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/nuouork10rdv_13_11zon.jpg',
        price: 45,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 54,
        title: 'كوكيز نيويورك رد فيلفيت صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/ردفلفت.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 55,
        title: 'كوكيز نيويورك رد فيلفيت حبة',
        description: 'حبة واحدة',
        image: '../../img/food/nuouork1rdv_8_11zon.jpg',
        price: 8,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 56,
        title: 'كوكيز نيويورك بيستاشيو كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/nuouork10bsta_10_11zon.jpg',
        price: 45,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 57,
        title: 'كوكيز نيويورك بيساتشيو صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/بستاشيو.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 58,
        title: 'كوكيز نيويورك بيستاشيو حبة',
        description: 'حبة واحدة',
        image: '../../img/food/nuouork1bsta_5_11zon.jpg',
        price: 8,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 59,
        title: 'كوكيز نيويورك كراميل كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/nuouork10kramil_12_11zon.jpg',
        price: 45,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 60,
        title: 'كوكيز نيويورك كراميل صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/كراميل.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 61,
        title: 'كوكيز نيويورك كراميل حبة',
        description: 'حبة واحدة',
        image: '../../img/food/nuouork1kramil_7_11zon.jpg',
        price: 8,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 62,
        title: 'كوكيز نيويورك مشكل كبير',
        description: 'العدد 10 حبة',
        image: '../../img/food/مشكل.jpg',
        price: 46,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 63,
        title: 'كوكيز نيويورك مشكل صغير',
        description: 'العدد 5 حبة',
        image: '../../img/food/مشكل2.jpg',
        price: 29,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 9,
    title: 'كيكات الكوكيز',
    image: '../../../img/list/cheesecake.png',
    selected: false,
    items: [
      {
        id: 66,
        title: 'كيكة الكوكيز الكلاسيك بالنوتيلا',
        description: 'قطعة مثلثة',
        image: '../../img/food/kikakoksnotlakta_14_11zon.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 67,
        title: 'كيكة الكوكيز الكلاسيك بالنوتيلا',
        description: 'كيكة صغيرة',
        image: '../../img/food/kikakoksnotla_13_11zon.jpg',
        price: 35,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 68,
        title: 'كيكة الكوكيز الكلاسيك بالنوتيلا',
        description: 'كيكة وسط',
        image: '../../img/food/kikakoksnotla_13_11zon.jpg',
        price: 79,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 69,
        title: 'كيكة الكوكيز الكلاسيك بالنوتيلا',
        description: 'كيكة كبيرة',
        image: '../../img/food/kikakoksnotla_13_11zon.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 10,
    title: 'الحلى',
    image: '../../../img/list/ice-cream.png',
    selected: false,
    items: [
      {
        id: 1,
        title: 'تيراميسو',
        description: '',
        image: '../../../img/food/tiramiso.jpg',
        price: 16,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: 'رايس كرسبي',
        description: '',
        image: '../../../img/food/rais-krspi.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'آيسكريم رايس كرسبي',
        description: '',
        image: '../../../img/food/ice-ris.jpg',
        price: 16,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'بودينغ الكوكيز',
        description: '',
        image: '../../../img/food/boding-kokiz.jpg',
        price: 24,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: 'بودينغ البراونيز',
        description: '',
        image: '../../../img/food/boding-braoniz.jpg',
        price: 25,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'قطع براونيز ساده',
        description: '',
        image: '../../../img/food/braoniz-sada.jpg',
        price: 15,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'براونيز مكس شوكلت ومكسرات',
        description: '',
        image: '../../../img/food/braoniz-mx.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'براونيز آيسكريم',
        description: '',
        image: '../../../img/food/ice-braoniz.jpg',
        price: 19,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'قطعة كوكيز كلاسيك مثلثة',
        description: '',
        image: '../../../img/food/cokiz-klassic.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'تشيز كيك لوتس',
        description: '',
        image: '../../../img/food/cheez-kik-lots.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 11,
        title: 'تشيز كيك بيستاشيو',
        description: '',
        image: '../../../img/food/cheez-kik-bstashio.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 12,
        title: 'تشيز كيك نوتيلا',
        description: '',
        image: '../../../img/food/cheez-kik-nutella.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 11,
    title: 'البوكسات العائلية',
    image: '../../../img/list/chocolate.png',
    selected: false,
    items: [
      {
        id: 1,
        title: 'بوكس موالح عائلي مشكل 1',
        description: 'عدد 6 أصناف',
        image: '../../../img/food/moal7-1.jpg',
        price: 89,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: 'بوكس موالح عائلي مشكل 2',
        description: 'عدد 6 أصناف',
        image: '../../../img/food/moal7-2.jpg',
        price: 89,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'بوكس موالح عائلي مشكل 3',
        description: 'عدد 6 أصناف',
        image: '../../../img/food/moal7-3.jpg',
        price: 95,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'بوكس عنبة و كوكيز',
        description: 'عدد 12 صنف',
        image: '../../../img/food/box-3nb.jpg',
        price: 119,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: 'بوكس الضيافة 1',
        description: 'أختيار 4 أصناف من أصل 8',
        image: '../../../img/food/box-diafh.jpg',
        price: 159,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'بوكس الضيافة 2',
        description: 'أختيار 4 أصناف من أصل 8',
        image: '../../../img/food/box-diafh-2.jpg',
        price: 159,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'بوكس الضيافة 3',
        description: 'أختيار 4 أصناف من أصل 8',
        image: '../../../img/food/box-diafh-3.jpg',
        price: 159,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'بوكس مشكل ورق عنب و ملفوف',
        description: 'عدد 60 حبه',
        image: '../../../img/food/box-mshkl60.jpg',
        price: 76,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'بوكس مشكل ورق عنب و ملفوف',
        description: 'عدد 100 حبه',
        image: '../../../img/food/box-mshkl100.jpg',
        price: 110,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'بوكس الدولمة محاشي مشكلة',
        description: '',
        image: '../../../img/food/box-dolmah.jpg',
        price: 79,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 12,
    title: 'المشروبات الباردة',
    image: '../../../img/list/mohi.png',
    selected: false,
    items: [
      {
        id: 0,
        title: 'قهوة اليوم',
        description: '',
        image: '../../img/food/8hoh-alyom.jpg',
        price: 4.95,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 1,
        title: 'آيس شوكلت',
        description: '',
        image: '../../img/food/ice-chooklit.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: 'أوريو فراب',
        description: '',
        image: '../../img/food/oreo-frab.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'شراب الكركدية',
        description: 'حجم صغير',
        image: '../../img/food/small-krkadih.jpg',
        price: 7,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'شراب الكركدية',
        description: 'حجم كبير',
        image: '../../img/food/big-krkadih.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: 'موهيتو توت أحمر',
        description: '',
        image: '../../img/food/mohito-a7mr.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'موهيتو توت أزرق',
        description: '',
        image: '../../img/food/mohito-azrg.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'موهيتو توت أسود',
        description: '',
        image: '../../img/food/mohito-asod.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'موهيتو فراولة',
        description: '',
        image: '../../img/food/mohito-fraolh.jpg',
        price: 13,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'موهيتو مكس بيري',
        description: '',
        image: '../../img/food/mohito-max.jpg',
        price: 14,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'موهيتو فيمتو',
        description: '',
        image: '../../img/food/mohito-femto.jpg',
        price: 14,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 13,
    title: 'المشروبات الغازية',
    image: '../../../img/list/ghas.png',
    selected: false,
    items: [
      {
        id: 120,
        title: 'ببسي',
        description: '',
        image: '../../img/food/bbse_4_11zon.jpg',
        price: 2.5,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 121,
        title: 'ببسي دايت',
        description: '',
        image: '../../img/food/dayt.jpg',
        price: 2.5,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 122,
        title: 'حمضيات',
        description: '',
        image: '../../img/food/hmth_2_11zon.jpg',
        price: 2.5,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 123,
        title: 'سفن اب',
        description: '',
        image: '../../img/food/cvn_7_11zon.jpg',
        price: 2.5,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 124,
        title: 'ديو',
        description: '',
        image: '../../img/food/deu_8_11zon.jpg',
        price: 2.5,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 125,
        title: 'مياه معدنيه',
        description: 'مجانية',
        image: '../../img/food/maa_13_11zon.jpg',
        price: 0,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 14,
    title: 'التوفير',
    image: '../../../img/list/cupcake.png',
    selected: false,
    items: [
      {
        id: 1,
        title: 'تيراميسو + قهوة اليوم',
        description: '',
        image: '../../../img/food/tramsio-8hoh.jpg',
        price: 19,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: 'كوكيز كلاسيك + قهوة اليوم',
        description: '',
        image: '../../../img/food/classik-kokiz-8hoh.jpg',
        price: 9,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: 'بودينغ كوكيز + قهوة اليوم',
        description: '',
        image: '../../../img/food/boding-cokiz-8hoh.jpg',
        price: 27,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: 'براونيز + قهوة اليوم',
        description: '',
        image: '../../../img/food/braoniz-8hoh.jpg',
        price: 18,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: 'بودينغ براونيز + قهوة اليوم',
        description: '',
        image: '../../../img/food/boding-braoniz-8hoh.jpg',
        price: 28,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: 'براونيز شوكلت ومكسرات + قهوة اليوم',
        description: '',
        image: '../../../img/food/braoniz-choklit-8hoh.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: 'براونيز آيسكريم + قهوة اليوم',
        description: '',
        image: '../../../img/food/braoniz-ice-8hoh.jpg',
        price: 22,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'رايس كرسبي + قهوة اليوم',
        description: '',
        image: '../../../img/food/rais-8hoh.jpg',
        price: 16,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'آيس رايس كرسبي + قهوة اليوم',
        description: '',
        image: '../../../img/food/ice-ris.jpg',
        price: 19,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 10,
        title: 'تشيز كيك لوتس + قهوة اليوم',
        description: '',
        image: '../../../img/food/cheez-kik-8hoh.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 11,
        title: 'تشيز كيك بيستاشيو + قهوة اليوم',
        description: '',
        image: '../../../img/food/bstashio-8hoh.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 12,
        title: 'تشيز كيك نوتيلا + قهوة اليوم',
        description: '',
        image: '../../../img/food/nutella-8hoh.jpg',
        price: 21,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
  {
    id: 15,
    title: 'المناسبات',
    image: '../../../img/list/party-trumpets.png',
    selected: false,
    items: [
      {
        id: 1,
        title: ' تعبئة صينية ورق عنب',
        description: 'عدد 60 حبه',
        image: '../../img/food/عنب.jpg',
        price: 79,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 2,
        title: ' تعبئة صينية ورق عنب',
        description: 'عدد 80 حبه',
        image: '../../img/food/عنب.jpg',
        price: 98,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 3,
        title: ' تعبئة صينية ورق عنب',
        description: 'عدد 100 حبه',
        image: '../../img/food/عنب.jpg',
        price: 115,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 4,
        title: ' تعبئة صينية ملفوف',
        description: 'عدد 60 حبه',
        image: '../../img/food/ملفوف.jpg',
        price: 79,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 5,
        title: ' تعبئة صينية ملفوف',
        description: 'عدد 80 حبه',
        image: '../../img/food/ملفوف.jpg',
        price: 98,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 6,
        title: ' تعبئة صينية ملفوف',
        description: 'عدد 100 حبه',
        image: '../../img/food/ملفوف.jpg',
        price: 115,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 7,
        title: ' تعبئة صينية ملفوف',
        description: 'عدد 100 حبه',
        image: '../../img/food/ملفوف.jpg',
        price: 115,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 8,
        title: 'تعبئة صينية معجنات صغير',
        description: 'عدد 36 حبه',
        image: '../../img/food/siniah-49.jpg',
        price: 49,
        calories: 0,
        available: true,
        selected: false,
      },
      {
        id: 9,
        title: 'تعبئة صينية معجنات كبير',
        description: 'عدد 60 حبه',
        image: '../../img/food/siniah-90.jpg',
        price: 90,
        calories: 0,
        available: true,
        selected: false,
      },
    ],
  },
]

export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
