import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Banner.css'

const Banner = () => {
    const settings = {
      dots: false, // لإظهار أو إخفاء النقاط السفلية
      infinite: true, // للتكرار اللانهائي
      speed: 500, // سرعة التنقل بين الشرائح
      slidesToShow: 1, // عدد الشرائح المراد عرضها في وقت واحد
      slidesToScroll: 1, // عدد الشرائح المراد تمريرها في كل حركة
      autoplay: true, // للتشغيل التلقائي
      autoplaySpeed: 3000, // سرعة التشغيل التلقائي بالمللي ثانية
      swipe: true, // لتفعيل السحب باللمس
      touchThreshold: 10, // لضبط عتبة السحب باللمس (قيمة أعلى تجعل السحب أسهل)
    };

  return (
    <div className="banner-container">
      <Slider {...settings}>
      <div>
          <img src="https://images.taker.io/shawarma_house/1721816464_qKkvKT.jpg" alt="Banner 0" tabindex="-1" />
        </div>
        <div>
          <img src="https://images.taker.io/shawarma_house/1721816548_9iSQuJ.jpg" alt="Banner 1" tabindex="-1" />
        </div>
        <div>
          <img src="https://images.taker.io/namqcaffee/1721121911_F3ShZT.jpg" alt="Banner 2" tabindex="-1" />
        </div>
        <div>
          <img src="https://wisalmedia.com/wp-content/uploads/2022/12/%D8%AC%D8%B3%D8%AC%D8%B3%D8%AC%D8%B3%D8%AC%D8%B36-scaled.jpg" alt="Banner 3" tabindex="-1" />
        </div>
        <div>
          <img src="https://wisalmedia.com/wp-content/uploads/2022/12/%D8%A8%D9%86%D8%B1%D9%862%D8%B1%D9%86%D8%B1%D9%86%D8%B1%D9%86%D8%B1-scaled.jpg" alt="Banner 4" tabindex="-1" />
        </div>
        <div>
          <img src="https://mostaql.hsoubcdn.com/uploads/portfolios/1174804/64e1debc1e6a6/%D8%A8%D9%86%D8%B11-0%D9%A1.jpg" alt="Banner 5" tabindex="-1" />
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
